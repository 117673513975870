<template>
  <div>
    <component :is="this.headerFooter.header"></component>
    <div class="container">
        <nav aria-label="breadcrumb" style="margin-top:20px;background-color:white">
            <ol class="breadcrumb">
                <li class="breadcrumb-item active" style="opacity:0.7;">产品介绍</li>
                <li class="breadcrumb-item active" aria-current="page" style="opacity:0.7;">医用口罩</li>
            </ol>
        </nav>
        <!-- <p class="product-title-style">一次性口罩</p> -->
        <div class="row" style="margin-top:60px;text-align:center">
            <div class="col-sm-3" v-viewer>
                <img :src="mask1_img" width="100%" height="80%"/>
                <!-- <p style="margin-top:10px;font-weight: bold;">(医用口罩)</p> -->
            </div>
            <div class="col-sm-9">
                <table class="table table-bordered product-table-style" style="width:100%;height:80%;border: 2px solid black;">
                    <thead>
                        <tr style="background-color: #BEBEBE;">
                            <th colspan="4" class="product-table-title-style">{{maskModel.mask_title1}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="background-color: #F0F0F0">
                            <th rowspan="2" class="product-table-td">产品规格</th>
                            <th rowspan="2" class="product-table-td">注册证号</th>
                            <th colspan="2">包装</th>
                        </tr>
                        <tr style="background-color: #F0F0F0">
                            <th>片/袋</th>
                            <th>片/箱</th>
                            
                        </tr>
                        <tr>
                            <th>{{maskModel.mask_format1}}</th>
                            <th>{{maskModel.mask_id1}}</th>
                            <th>{{maskModel.mask_bag1}}</th>
                            <th>{{maskModel.mask_box1}}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" style="margin-top:60px;text-align:center">
             <div class="col-sm-3" v-viewer>
                <img :src="mask2_img" width="100%" height="80%"/>
                <!-- <p style="margin-top:10px;font-weight: bold;">(医用外科口罩)</p> -->
            </div>
            <div class="col-sm-9">
                <table class="table table-bordered product-table-style" style="width:100%;height:80%;border: 2px solid black;">
                    <thead>
                        <tr style="background-color: #BEBEBE;">
                            <th colspan="4" class="product-table-title-style">{{maskModel.mask_title2}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="background-color: #F0F0F0">
                            <th rowspan="2" class="product-table-td">产品规格</th>
                            <th rowspan="2" class="product-table-td">注册证号</th>
                            <th colspan="2">包装</th>
                        </tr>
                        <tr style="background-color: #F0F0F0">
                            <th>片/袋</th>
                            <th>片/箱</th>
                            
                        </tr>
                        <tr>
                            <th>{{maskModel.mask_format2}}</th>
                            <th>{{maskModel.mask_id2}}</th>
                            <th>{{maskModel.mask_bag2}}</th>
                            <th>{{maskModel.mask_box2}}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" style="margin-top:60px;text-align:center">
             <div class="col-sm-3" v-viewer >
                <img :src="mask3_img" width="100%" height="80%"/>
                <!-- <p style="margin-top:10px;font-weight: bold;">(医用外科口罩盒装)</p> -->
            </div>
            <div class="col-sm-9">
                <table class="table table-bordered product-table-style" style="width:100%;height:80%;border: 2px solid black;">
                    <thead>
                        <tr style="background-color: #BEBEBE;">
                            <th colspan="4" class="product-table-title-style">{{maskModel.mask_title3}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="background-color: #F0F0F0">
                            <th rowspan="2" class="product-table-td">产品规格</th>
                            <th rowspan="2" class="product-table-td">注册证号</th>
                            <th colspan="2">包装</th>
                        </tr>
                        <tr style="background-color: #F0F0F0">
                            <th>片/袋</th>
                            <th>片/盒</th>
                            
                        </tr>
                        <tr>
                            <th>{{maskModel.mask_format3}}</th>
                            <th>{{maskModel.mask_id3}}</th>
                            <th>{{maskModel.mask_bag3}}</th>
                            <th>{{maskModel.mask_box3}}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" style="margin-top:60px;text-align:center">
            <div class="col-sm-3" v-viewer>
                <img :src="mask4_img" width="100%" height="50%"/>
                <!-- <p style="margin-top:10px;font-weight: bold;">(儿童口罩)</p> -->
            </div>
            <div class="col-sm-9">
                <table class="table table-bordered product-table-style" style="width:100%;height:50%;border: 2px solid black;">
                    <thead>
                        <tr style="background-color: #BEBEBE;">
                            <th colspan="4" class="product-table-title-style">{{maskModel.mask_title4}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="background-color: #F0F0F0">
                            <th rowspan="2" class="product-table-td">产品规格</th>
                            <th rowspan="2" class="product-table-td">注册证号</th>
                            <th colspan="2">包装</th>
                        </tr>
                        <tr style="background-color: #F0F0F0">
                            <th>片/袋</th>
                            <th>片/箱</th>
                            
                        </tr>
                        <tr>
                            <th>{{maskModel.mask_format4}}</th>
                            <th>{{maskModel.mask_id4}}</th>
                            <th>{{maskModel.mask_bag4}}</th>
                            <th>{{maskModel.mask_box4}}</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div style="text-align:center;margin-top:-150px">
            <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/mask5.png" width="50%" height="70%" zoom=1/>
        </div>
        <div style="text-align:center;margin-top:30px;margin-bottom:50px" v-viewer>
            <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/mask6.png" width="50%" height="70%" zoom=1/>
        </div>
    </div>
    <component :is="this.headerFooter.Footer"></component>
  </div>
</template>
<script>
import config from "@/config.js"
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import store from "@/store"
import { getFileList} from '@/js/aws-s3'
export default {
    components: {
        Header,
        Footer
    },
    data(){
        return{
            maskModel: {
                mask_title1: "", //0
                mask_format1: "",
                mask_id1: "",
                mask_bag1: "",
                mask_box1: "",
                mask_title2: "", //5
                mask_format2: "",
                mask_id2: "",
                mask_bag2: "",
                mask_box2: "",
                mask_title3: "", //10
                mask_format3: "",
                mask_id3: "",
                mask_bag3: "",
                mask_box3: "",
                mask_title4: "", //15
                mask_format4: "",
                mask_id4: "",
                mask_bag4: "",
                mask_box4: "", 
            },
            mask1_img: 'https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/mask1.png',
            mask2_img: 'https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/mask2.png',
            mask3_img: 'https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/mask3.jpg',
            mask4_img: 'https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/mask4.jpg',
        }
    },
    computed: {
		headerFooter() {
            return store.getters['auth/isLoggedIn'] ? {
                Footer: Footer,
            } : {
                header: Header,
                Footer: Footer
            }
        }
	},
    created(){
        this.getMaskInfo()
        this.getAllMaskImg()
    },
    methods: {
        getMaskInfo(){
            this.isLoading = true
            const api = `${config.ApiURL}/product/mask`;
            this.$http.get(api).then((res) => {
                if(res.data.Status === "success"){
                    this.maskInfo = res.data.data
                    this.setupMaskInfo()
                }
            })
        },
        setupMaskInfo(){
            this.maskInfo.sort(this.sortId)

            this.maskModel.mask_title1 = this.maskInfo[0].mask_title
            this.maskModel.mask_format1 = this.maskInfo[0].mask_format
            this.maskModel.mask_id1 = this.maskInfo[0].mask_id
            this.maskModel.mask_bag1 = this.maskInfo[0].mask_bag
            this.maskModel.mask_box1 = this.maskInfo[0].mask_box

            this.maskModel.mask_title2 = this.maskInfo[1].mask_title
            this.maskModel.mask_format2 = this.maskInfo[1].mask_format
            this.maskModel.mask_id2 = this.maskInfo[1].mask_id
            this.maskModel.mask_bag2 = this.maskInfo[1].mask_bag
            this.maskModel.mask_box2 = this.maskInfo[1].mask_box

            this.maskModel.mask_title3 = this.maskInfo[2].mask_title
            this.maskModel.mask_format3 = this.maskInfo[2].mask_format
            this.maskModel.mask_id3 = this.maskInfo[2].mask_id
            this.maskModel.mask_bag3 = this.maskInfo[2].mask_bag
            this.maskModel.mask_box3 = this.maskInfo[2].mask_box

            this.maskModel.mask_title4 = this.maskInfo[3].mask_title
            this.maskModel.mask_format4 = this.maskInfo[3].mask_format
            this.maskModel.mask_id4 = this.maskInfo[3].mask_id
            this.maskModel.mask_bag4 = this.maskInfo[3].mask_bag
            this.maskModel.mask_box4 = this.maskInfo[3].mask_box

            this.isLoading = false
        },
        async getAllMaskImg(){
            const imgFolder = ['mask1', 'mask2', 'mask3', 'mask4']
            const imgPath = []
            for(let i = 0; i<imgFolder.length;i++){
                await getFileList(`web/img/product/mask/${imgFolder[i]}`).then((res) => {
                    const data = res.Contents
                    if(res.Contents.length == 2){
                        imgPath.push(data[1].Key)
                    }
                })
            }
            this.mask1_img =`https://hxesther.s3.ap-northeast-3.amazonaws.com/${imgPath[0]}`
            this.mask2_img = `https://hxesther.s3.ap-northeast-3.amazonaws.com/${imgPath[1]}`
            this.mask3_img = `https://hxesther.s3.ap-northeast-3.amazonaws.com/${imgPath[2]}`
            this.mask4_img = `https://hxesther.s3.ap-northeast-3.amazonaws.com/${imgPath[3]}`
        },
        sortId(a,b){
            return a.id-b.id
        }
    }
};
</script>
<style scoped>
a {
    text-decoration: none;
    color: black;
}

.router-link-active {
    text-decoration: none;
}
</style>