var S3 = require('aws-sdk/clients/s3');
// var fs = require('fs')

const bucketName = "hxesther"
const region = "ap-northeast-3"
const accessKeyId = "AKIAQOE55SCW7MLBZSAL"
const secretAccessKey = "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"

const s3 = new S3({
    region,
    accessKeyId,
    secretAccessKey
})

export const getFileList =  (folderPath) => {
    var listParams = {
        Bucket: bucketName, 
        Prefix: folderPath
    };

    return s3.listObjects(listParams).promise()
}

export const uploadFile =  (filePath, file) => {
    // const fileStream = fs.createReadStream(file)

    var uploadParams = {
        Bucket: bucketName,
        Body: file,
        Key: filePath
    }

    return s3.upload(uploadParams).promise()
}

export const getFile =  (filePath) => {
    var getParams = {
        Bucket: bucketName,
        Key: filePath 
    }

    return s3.getObject(getParams).promise()
}

export const deleteFile =  (filePath) => {
    var params = {
        Bucket: bucketName, 
        Key: filePath
    };

    return s3.deleteObject(params).promise()
}

export { s3 }