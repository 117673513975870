<template>
  <div>
    <component :is="this.headerFooter.header"></component>
    <vue-element-loading
      :active="isLoading"
      is-full-screen
      text="载入中，请稍后..."
    />
    <div class="container">
      <nav
        aria-label="breadcrumb"
        style="margin-top: 20px; background-color: white"
      >
        <ol class="breadcrumb">
          <li class="breadcrumb-item" style="opacity: 0.7">
            <router-link to="/" style="opacity: 0.7">首页</router-link>
          </li>
          <li class="breadcrumb-item active" style="opacity: 0.7">
            <router-link to="/companyNews" style="opacity: 0.7"
              >新闻资讯</router-link
            >
          </li>
          <li
            class="breadcrumb-item active"
            aria-current="page"
            style="opacity: 0.7"
          >
            公司新闻
          </li>
        </ol>
      </nav>
      <nav>
        <div class="container" style="background-color: white">
          <div class="tabs">
            <ul>
              <li>
                <a class="selected"
                  ><router-link to="/companyNews">公司新闻</router-link></a
                >
              </li>
              <li style="margin-left: 35px">
                <a
                  ><router-link to="/diseaseColumn"
                    >心血管疾病专栏</router-link
                  ></a
                >
              </li>
              <li style="margin-left: 35px">
                <a><router-link to="/studio">程博工作室</router-link></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="news-box" v-for="item in newsInfoData" :key="item">
        <div class="card" style="margin-top: 10px">
          <div class="card-header news-card-title-style">
            <p>{{ item.release_datetime }}</p>
            <h5>{{ item.news_title }}</h5>
          </div>
          <div class="card-body">
            <p class="card-text">{{ item.news_Introduction }}</p>
            <a href="" class="btn btn-info" @click="goCompanyNewsInfo(item.id)"
              >详细讯息</a
            >
          </div>
        </div>
      </div>
      <div style="height: 200px"></div>
    </div>
    <component :is="this.headerFooter.Footer"></component>
  </div>
</template>
<script>
import config from "@/config.js";
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import store from "@/store";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      newsInfoData: [],
      isLoading: false,
    };
  },
  computed: {
    headerFooter() {
      return store.getters["auth/isLoggedIn"]
        ? {
            Footer: Footer,
          }
        : {
            header: Header,
            Footer: Footer,
          };
    },
  },
  created() {
    this.getCompanyData();
  },
  methods: {
    getCompanyData() {
      this.newsInfoData = [];
      this.isLoading = true;
      const api = `${config.ApiURL}/news/companyNews?status=1`;
      this.$http.get(api).then((res) => {
        if (res.data.Status === "success") {
          this.newsInfoData = res.data.data.reverse();
          this.isLoading = false;
        }
      });
    },
    goCompanyNewsInfo(news_id) {
      this.$router.push({
        path: "/companyNewsInfo",
        query: { newsId: news_id },
      });
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
  color: black;
}

.router-link-active {
  text-decoration: none;
}
</style>
