<template>
    <div>
        <component :is="this.headerFooter.header"></component>
        <section class="about_us_main_1">
            <img src="https://hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/imgs/cell.png" style="width:100%;height:450px"/>
        </section>
        <div class="container">
            <nav aria-label="breadcrumb" style="margin-top:30px;background-color:white">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item active" style="opacity:0.7;">产品介绍</li>
                    <li class="breadcrumb-item active" aria-current="page" style="opacity:0.7;">生医技术</li>
                </ol>
            </nav>
            <div class="ab-group">
                <div style="text-align:center">
                    <h2 class="about-title-style">生医技术</h2>
                    <p class="product-bio-p-style">现代化高科技生物医学公司，得到科技部和河南省科技厅领导的肯定和赞赏，主要产品以抗菌止血类人造皮肤敷片为主，同时提供国际及全国相关领域中所需要的抗菌及杀菌原料，所有原料处方以及产品设计均由本公司研发人员研发生产并申请专利。</p>
                    <p class="product-bio-p-style">本公司在开发与生产产品的同时，以组织工程学领域中创伤免疫系统的开发为未来发展精益求精更进一步提供新产品。公司预计在2015年底以前，生产抗菌止血类 人造皮肤敷片，本产品的功能主要为提供给烧烫伤病患者以及糖尿病溃疡患者所需要的皮肤再生，杀菌止血为主。 2016之后将开发新的技术，使用类人造皮肤架构自身血液单细胞(progenitorcell)基底，培养单源母细胞的纤维支架。</p>
                    <div class="row" style="margin-top:80px">
                        <div class="col-sm">
                            <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/med-01.png" width="90%" height="90%"/>
                        </div>
                        <div class="col-sm">
                            <h1 class="product-bio-h1-style">包覆银</h1>
                            <p class="product-bio-p-style">产品简介：本产品是在纳米银的基础上，利用二氧化矽的多孔、孔道有序、孔径可调的特性，用二氧化矽包覆纳米银颗粒生产出的一种固态粉末。包覆银粉末兼具纳米银高效抗菌性能，同时降低纳米银的生物毒性，提高了生物安全性又开阔了因杀菌剂的应用范围。</p>
                            <p class="product-bio-p-style">适用范围：适用于医疗器械、环境工程等行业。</p>
                            <p class="product-bio-p-style">注意事项：粉末状物品，避免过量吸入。</p>
                        </div>
                    </div>
                    <div class="row" style="margin-top:80px">
                        <div class="col-sm">
                            <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/med-02.png" width="90%" height="90%"/>
                        </div>
                        <div class="col-sm">
                            <h1 class="product-bio-h1-style">海藻酸盐抗菌护创敷料</h1>
                            <p class="product-bio-p-style">产品简介：本产品是一种新型医用敷料，由医用无纺布胶带和基质组成，原材料海藻酸盐、壳聚糖均采用天然高分子化合物。产品具有良好生物相容性、吸湿性、不粘连、良好抗菌性能，可以改善伤口局部的血流供应，促进组织生长，加快创面愈合。</p>
                            <p class="product-bio-p-style">适用范围：适用于体表溃疡、烫伤、Ⅰ—IV期褥疮、糖尿病足湿性溃疡、Ⅰ—Ⅱ度烧伤创面，足癣湿敷等治疗。</p>
                            <p class="product-bio-p-style">注意事项：本产品仅限外用；包装破损或超过灭菌有效期，产品严禁使用；使用该贴应紧贴伤口；一次性使用，用后销毁。</p>
                        </div>
                    </div>
                    <div class="row" style="margin-top:80px">
                        <div class="col-sm">
                            <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/med-03.png" width="90%" height="90%"/>
                        </div>
                        <div class="col-sm">
                            <h1 class="product-bio-h1-style">环保杀菌球</h1>
                            <p class="product-bio-p-style">产品简介：该产品主要由天然高分子化合物和包覆银为主要原料，褐色，球状。</p>
                            <p class="product-bio-p-style">适用范围：主要用于鱼缸、鱼塘、水产养殖等水环境的消毒处理，具有缓释性、药效持久、杀菌广谱等特点。</p>
                            <p class="product-bio-p-style">注意事项：水环境杀菌需定期更换杀菌球。</p>
                        </div>
                    </div>
                    <div class="row" style="margin-top:80px">
                        <div class="col-sm">
                            <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/med-04.png" width="90%" height="90%"/>
                        </div>
                        <div class="col-sm">
                            <h1 class="product-bio-h1-style">饲料添加剂</h1>
                            <p class="product-bio-p-style">产品简介：本产品采用包覆银和天然矿物质复合而成，本品为黄色粉末。</p>
                            <p class="product-bio-p-style">适用范围：主要用于禽类养殖饲料添加，具有促消化、预防禽类流行病的功效。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <component :is="this.headerFooter.Footer"></component>
    </div>
</template>

<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import store from "@/store"
export default {
    components: {
        Header,
        Footer
    },
    data(){
        return{
            
        }
    },
    computed: {
		headerFooter() {
            return store.getters['auth/isLoggedIn'] ? {
                Footer: Footer,
            } : {
                header: Header,
                Footer: Footer
            }
        }
	},
    methods: {
        
    }
};
</script>