<template>
  <div>
    <component :is="this.headerFooter.header"></component>
    <div class="container">
        <nav aria-label="breadcrumb" style="margin-top:20px;;background-color:white">
            <ol class="breadcrumb">
                <li class="breadcrumb-item" style="opacity:0.7;"><router-link to="/" style="opacity:0.7;">首页</router-link></li>
                <li class="breadcrumb-item active" style="opacity:0.7;"><router-link to="/ecardiacApp" style="opacity:0.7;">App下载</router-link></li>
                <li class="breadcrumb-item active" aria-current="page" style="opacity:0.7;">顾问平台App</li>
            </ol>
        </nav>
        <nav>
            <div class="container" style=";background-color:white">
                <div class="tabs">
                    <ul>
                        <li><a><router-link to="/ecardiacApp">心测宝App</router-link></a></li>
                        <li style="margin-left:35px"><a class="selected"><router-link  to="/consultantPlatformApp">顾问平台App</router-link></a></li>
                    </ul>
                </div>
            </div>
        </nav>
        <div style="text-align:center;margin-top:40px">
            <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/ConsultantPlatform_v2_512.png" width="15%" height="15%" style="margin-top:30px;"/>
            <p class="appDownload-p-style">健康云</p>
            <p class="appDownload-p-style1">顾问管理平台</p>
            <p class="appDownload-p-style1">App 下载</p>
            <div class="col" style="margin-bottom:40px">
                <a href="https://hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/doctorApp/apk/Consultant_v2_27.apk">
                    <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/android_logo.png" width="80px" height="80px" style="margin-top:30px;"/>
                </a>
                <a href="https://itunes.apple.com/app/id1445672432" style="margin-left:60px">
                    <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/apple_logo.png" width="80px" height="80px" style="margin-top:30px;"/>
                </a>
            </div>
        </div>
    </div>
    <component :is="this.headerFooter.Footer"></component>
  </div>
</template>
<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import store from "@/store"
export default {
    components: {
        Header,
        Footer
    },
    data(){
        return{
            
        }
    },
    computed: {
		headerFooter() {
            return store.getters['auth/isLoggedIn'] ? {
                Footer: Footer,
            } : {
                header: Header,
                Footer: Footer
            }
        }
	},
    methods: {
        
    }
};
</script>
<style scoped>
a {
    text-decoration: none;
    color: black;
}

.router-link-active {
    text-decoration: none;
}
</style>