<template>
  <div>
    <component :is="this.headerFooter.header"></component>
    <div class="container" style="margin-top:40px">
      <froalaView v-model="model"></froalaView>
    </div>
    <component :is="this.headerFooter.Footer"></component>
  </div>
</template>
<script>
import config from "@/config.js"
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import store from "@/store"
export default {
    components: {
        Header,
        Footer
    },
    data(){
        return{
          article_id: this.$route.query.articleId,
          model: ''
        }
    },
    computed: {
		headerFooter() {
      return store.getters['auth/isLoggedIn'] ? {
          Footer: Footer,
      } : {
          header: Header,
          Footer: Footer
      }
    }
	},
  created(){
    this.getNewsData()
  },
  methods: {
      getNewsData(){
        const api = `${config.ApiURL}/news/articleData?article_id=${this.article_id}`;
        this.$http.get(api).then((res) => {
            if(res.data.Status === 'success'){
                this.model = res.data.data[0].article_text
            }
        })
    },
  }
};
</script>