var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(this.headerFooter.header,{tag:"component"}),_c('b-carousel',{staticClass:"footer-width",staticStyle:{"text-shadow":"0px 0px 2px #000"},attrs:{"id":"carousel-fade","fade":"","interval":"5000","indicators":"","no-hover-pause":"","img-width":"1024","img-height":"480"}},[_c('b-carousel-slide',{attrs:{"img-src":"https://hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/hxestherWeb/health-title-cn-02.png"}}),_c('b-carousel-slide',{attrs:{"img-src":"https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/health-title-cn-01.png"}})],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c(this.headerFooter.Footer,{tag:"component",staticClass:"footer-width"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative","width":"100%"}},[_c('div',{staticClass:"product-bg0"},[_c('div',{staticClass:"row",staticStyle:{"margin-left":"55%","width":"600px"}},[_c('div',{staticClass:"col-sm product-text-box"},[_c('p',{staticStyle:{"font-size":"50px","color":"#006349","text-align":"center","margin-top":"20px","font-weight":"900"}},[_vm._v("心测宝")]),_c('p',{staticStyle:{"font-size":"40px","color":"#006349","text-align":"center"}},[_vm._v("便携式心脏功能检测仪")]),_c('p',{staticStyle:{"font-size":"60px","color":"#006349","text-align":"center","margin-top":"-30px"}},[_vm._v("+")]),_c('p',{staticStyle:{"font-size":"40px","color":"#006349","text-align":"center","margin-top":"-30px"}},[_vm._v("心脏健康管理")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-bg2"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 product-text-box"},[_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"24px","color":"#D97152"}},[_vm._v("及时洞悉心脏异常 您需要一台便携式心脏功能检测仪")]),_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"18px"}},[_vm._v("心脏疾病具有突发性，由于它的发生非常的突然，经常让人措手不及，如果不能及时的进行诊断抢救的话，是很容易威胁生命健康的。大部分心脏疾病都早有预警先兆，而很多人无法甄别这些危险信号，才导致疾病发生。")])]),_c('div',{staticClass:"col-sm-6"},[_c('img',{attrs:{"src":"https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/product/e2.png","width":"100%","height":"480px"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-bg3",staticStyle:{"height":"500px"}},[_c('div',{staticClass:"row",staticStyle:{"margin-left":"50%","width":"600px"}},[_c('div',{staticClass:"col-sm product-text-box"},[_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"24px","color":"#006349"}},[_vm._v("六盈心测宝 随时掌控心脏健康状态")]),_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"18px"}},[_vm._v("利用现代医学信息学与传统中医相结合的分析技术，提取心血管系统生理病理状态特征信息，经过AI人工智能计算实现对心跳紊乱、心律不齐、心肌缺血、心室功能不全、心脏传导阻滞等心血管疾病相关症状的精准监测，万例临床结果对照率达"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("99.9%")]),_vm._v("。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"product-bg4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 product-text-box"},[_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"24px","color":"#D97152"}},[_vm._v("老人也可轻松使用")]),_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"18px"}},[_vm._v("使用简单，小巧便携，平时居家或者出差皆可放在手提包里随身携带，怀疑得了“心病”，可立即拿出“心测宝”测一测,APP会立即给予提醒。根据测量报告，结合症状轻重和持续情况,任何人都能对自身情况有了合理的判断。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-bg5",staticStyle:{"height":"450px"}},[_c('div',{staticClass:"row",staticStyle:{"margin-left":"50%","width":"600px"}},[_c('div',{staticClass:"col-sm product-text-box"},[_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"24px","color":"#006349"}},[_vm._v("数据共享 远程监护父母心脏健康")]),_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"18px","margin-bottom":"50px"}},[_vm._v("通过亲友登录，不论您身处何方，打开APP就可以看的家人的心脏健康数据，可以随时给父母打电话，询问状况，及时提醒。让家人感受满满的爱")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-bg6"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 product-text-box"},[_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"24px","color":"#D97152"}},[_vm._v("个性化健康管理服务")]),_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"18px"}},[_vm._v("健康管理师（医生）给用户提供健康指导、饮食改善意见、运动干预、以及用药指导或者提醒等主动和用户沟通的方式，促进患者提高自身健康意识，改善不良生活习惯，减少一些突发病情的发生，提高生命品质，提升幸福指数。")])]),_c('div',{staticClass:"col-sm-6"},[_c('img',{attrs:{"src":"https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/product/e6.png","width":"100%","height":"480px"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-bg7",staticStyle:{"height":"450px"}},[_c('div',{staticClass:"row",staticStyle:{"margin-left":"50%","width":"600px"}},[_c('div',{staticClass:"col-sm product-text-box"},[_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"24px","color":"#006349"}},[_vm._v("多样合作 多元应用")]),_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"18px"}},[_vm._v("产品可直接面对终端客户，也可与医院、社区、康养机构、政府及事业单位等合作，提供产品定制服务。")]),_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"18px","margin-bottom":"55px"}},[_vm._v("可应用于居家远程照护，慢病院内院外管理，以及智慧城市、智慧病房、智慧社区、养老产业、企业健康小屋等的建设。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-bg8"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 product-text-box"},[_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"24px","color":"#D97152"}},[_vm._v("注意事项")]),_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"18px"}},[_vm._v("1.只需按照使用说明书步骤使用即可。")]),_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"18px"}},[_vm._v("2.测量时请尽可能保持平静状态，避免因肢体动作引起干扰，导致最终检测数据发生误差。")]),_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"18px"}},[_vm._v("3.测量结果反差过大时，请休息2分钟重新测量确认。")]),_c('p',{staticClass:"product-ecardiac-p-style",staticStyle:{"font-size":"18px","margin-bottom":"55px"}},[_vm._v("4.请使用个人账号测量，以免影响统计结果，造成结果偏差。")])]),_c('div',{staticClass:"col-sm-6"},[_c('img',{attrs:{"src":"https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/product/e8.png","width":"100%","height":"450px"}})])])])])
}]

export { render, staticRenderFns }