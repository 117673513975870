<template>
  <div>
    <component :is="this.headerFooter.header"></component>
    <div class="container">
      <nav aria-label="breadcrumb" style="margin-top:20px;background-color:white">
          <ol class="breadcrumb">
              <li class="breadcrumb-item" style="opacity:0.7;"><router-link to="/" style="opacity:0.7;">首页</router-link></li>
              <li class="breadcrumb-item active" style="opacity:0.7;"><router-link to="/companyNews" style="opacity:0.7;">新闻资讯</router-link></li>
              <li class="breadcrumb-item active" aria-current="page" style="opacity:0.7;">程博工作室</li>
          </ol>
      </nav>
      <nav>
          <div class="container" style="background-color:white">
              <div class="tabs">
                  <ul>
                      <li><a><router-link to="/companyNews">公司新闻</router-link></a></li>
                      <li style="margin-left:35px"><a><router-link  to="/diseaseColumn">心血管疾病专栏</router-link></a></li>
                      <li style="margin-left:35px"><a class="selected"><router-link  to="/studio">程博工作室</router-link></a></li>
                  </ul>
              </div>
          </div>
      </nav>
      <div class="news-info-box">
        <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/cherngInfo.png" width="70%" height="70%" style="margin-bottom:60px;margin-top:30px;"/>
        <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/professional.png" width="70%" height="70%" style="margin-bottom:60px;margin-top:30px;"/>
      </div>
    </div>
    <component :is="this.headerFooter.Footer"></component>
  </div>
</template>
<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import store from "@/store"
export default {
    components: {
        Header,
        Footer
    },
    data(){
        return{
            
        }
    },
    computed: {
		headerFooter() {
            return store.getters['auth/isLoggedIn'] ? {
                Footer: Footer,
            } : {
                header: Header,
                Footer: Footer
            }
        }
	},
    methods: {
        
    }
};
</script>
<style scoped>
a {
    text-decoration: none;
    color: black;
}

.router-link-active {
    text-decoration: none;
}
</style>