<template>
    <div>
        <component :is="this.headerFooter.header"></component>
        <!-- <section class="about_us_main_1">
            <img src="https://hxesther.s3.ap-northeast-3.amazonaws.com/web/img/other/various/about_bk.png" style="width:100%;height:350px"/>
        </section> -->
        <div class="container">
            <nav aria-label="breadcrumb" style="margin-top:30px;background-color:white">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" style="opacity:0.7;"><router-link to="/" style="opacity:0.7;">首页</router-link></li>
                    <li class="breadcrumb-item active" style="opacity:0.7;"><router-link to="/introduction" style="opacity:0.7;">关于以斯帖</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page" style="opacity:0.7;">公司简介</li>
                </ol>
            </nav>
            <nav>
                <div class="container" style="background-color:white">
                    <div class="tabs">
                        <ul>
                            <li><a class="selected"><router-link to="/introduction">公司简介</router-link></a></li>
                            <li style="margin-left:35px"><a><router-link  to="/honor">公司荣誉</router-link></a></li>
                            <li style="margin-left:35px"><a><router-link  to="/culture">公司文化</router-link></a></li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div class="card mb-3" style="max-width: 100%;margin-top:40px;">
                <div class="row no-gutters">
                    <div class="col-md-4">
                        <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/company_01.png" style="width:100%;height:100%">
                    </div>
                    <div class="col-md-8">
                        <div class="card-body">
                            <h2 class="card-title" style="margin-top:20px">河南华夏以斯帖生物科技有限公司</h2>
                            <p class="card-text" style="margin-top:40px">华夏以斯帖创立于2015年，是一家国际领先的“AI”智能健康管理服务商，我们致力于解决中老年人非流行性慢性疾病的二级预防及个性化健康管理，坚持“为全民健康服务”的宗旨，为每一个人提供一个不分地域和时间限制的、方便的、可靠的、个性化的健康信息资源。</p>
                            <p class="card-text">2016年在台湾高雄设立非流行性慢性疾病物联网研发中心，依靠先进的信息技术手段，利用现代医学、特色医学、中医学的理论、经验、成果、标准和指南，整合优质医疗卫生服务资源，构建国际唯一的二级预防及健康管理分析系统，为人类健康福祉的提升开辟了新道路。</p>
                            <p class="card-text">在不断探索健康新模式的同时，同企业界、投资界、科研界、学术界等开展广泛协助与联动，逐步建立起完善的健康科创战略和品牌服务生态链，为全人类创造更多、更好、更大的健康领域的合作空间与发展商机。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height:30px"/>
        <component :is="this.headerFooter.Footer"></component>
    </div>
</template>

<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import store from "@/store"
export default {
    components: {
        Header,
        Footer
    },
    data(){
        return{
            
        }
    },
    computed: {
		headerFooter() {
            return store.getters['auth/isLoggedIn'] ? {
                Footer: Footer,
            } : {
                header: Header,
                Footer: Footer
            }
        }
	},
    methods: {
        
    }
};
</script>
<style scoped>
a {
    text-decoration: none;
    color: black;
}

.router-link-active {
    text-decoration: none;
}
</style>