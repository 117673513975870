<template>
  <div>
    <component :is="this.headerFooter.header"></component>
    <!-- <section class="about_us_main_1">
        <img src="https://hxesther.s3.ap-northeast-3.amazonaws.com/web/img/other/various/about_bk.png" style="width:100%;height:350px"/>
    </section> -->
    <div class="container">
        <nav aria-label="breadcrumb" style="margin-top:20px;background-color:white">
            <ol class="breadcrumb">
                <li class="breadcrumb-item" style="opacity:0.7;"><router-link to="/" style="opacity:0.7;">首页</router-link></li>
                <li class="breadcrumb-item active" style="opacity:0.7;"><router-link to="/introduction" style="opacity:0.7;">关于以斯帖</router-link></li>
                <li class="breadcrumb-item active" aria-current="page" style="opacity:0.7;">公司荣誉</li>
            </ol>
        </nav>
        <nav>
            <div class="container" style="background-color:white">
                <div class="tabs">
                    <ul>
                        <li><a><router-link to="/introduction">公司简介</router-link></a></li>
                        <li style="margin-left:35px"><a class="selected"><router-link  to="/honor">公司荣誉</router-link></a></li>
                        <li style="margin-left:35px"><a><router-link  to="/culture">公司文化</router-link></a></li>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="row" style="margin-top:30px">
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor14.png" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor15.jpg" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor6.jpg" width="100%" height="100%"/>
            </div>
        </div>
        <div class="row" style="margin-top:30px">
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor16.jpg" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor17.jpg" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor26.jpg" width="100%" height="100%"/>
            </div>
        </div>
        <div class="row" style="margin-top:30px">
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor27.jpg" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor28.jpg" width="100%" height="100%"/>
            </div>
        </div>
        <div class="row" style="margin-top:30px">
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor10.jpg" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor11.jpg" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor12.png" width="100%" height="100%"/>
            </div>
        </div>
        <div class="row" style="margin-top:30px">
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor7.jpg" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor8.jpg" width="100%" height="100%"/>
            </div>  
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor18.jpg" width="100%" height="100%"/>
            </div>     
        </div>
        <div class="row" style="margin-top:30px">
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor19.jpg" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor20.jpg" width="100%" height="100%"/>
            </div>  
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor21.jpg" width="100%" height="100%"/>
            </div>     
        </div>
        <div class="row" style="margin-top:30px">
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor22.jpg" width="100%" height="100%"/>
            </div>    
        </div>
        <div class="row" style="margin-top:40px">
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor1.png" width="100%" height="100%" zoom=1/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor2.png" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor3.png" width="100%" height="100%"/>
            </div>
        </div>
        <div class="row" style="margin-top:30px;margin-bottom:50px">
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor4.png" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor5.jpg" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/business%20-license.jpg" width="100%" height="100%"/>
            </div>
        </div>
        <div class="row" style="margin-top:30px;margin-bottom:50px">
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor23.png" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor24.jpg" width="100%" height="100%"/>
            </div>
            <div class="col-sm-4" v-viewer>
                <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/honor25.jpg" width="100%" height="100%"/>
            </div>
        </div>
    </div>
    <component :is="this.headerFooter.Footer"></component>
  </div>
</template>
<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import store from "@/store"
export default {
    components: {
        Header,
        Footer
    },
    data(){
        return{
            
        }
    },
    computed: {
		headerFooter() {
            return store.getters['auth/isLoggedIn'] ? {
                Footer: Footer,
            } : {
                header: Header,
                Footer: Footer
            }
        }
	},
    methods: {
        
    }
};
</script>
<style scoped>
a {
    text-decoration: none;
    color: black;
}

.router-link-active {
    text-decoration: none;
}
</style>