<template>
    <div>
        <component :is="this.headerFooter.header"></component>
        <!-- <section class="about_us_main_1">
            <img src="https://hxesther.s3.ap-northeast-3.amazonaws.com/web/img/other/various/about_bk.png" style="width:100%;height:350px"/>
        </section> -->
        <div class="container">
            <nav aria-label="breadcrumb" style="margin-top:30px;background-color:white">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item" style="opacity:0.7;"><router-link to="/" style="opacity:0.7;">首页</router-link></li>
                    <li class="breadcrumb-item active" style="opacity:0.7;"><router-link to="/introduction" style="opacity:0.7;">关于以斯帖</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page" style="opacity:0.7;">公司文化</li>
                </ol>
            </nav>
            <nav>
                <div class="container" style="background-color:white">
                    <div class="tabs">
                        <ul>
                            <li><a><router-link to="/introduction">公司简介</router-link></a></li>
                            <li style="margin-left:35px"><a><router-link  to="/honor">公司荣誉</router-link></a></li>
                            <li style="margin-left:35px"><a class="selected"><router-link  to="/culture">公司文化</router-link></a></li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div class="container" style="margin-top:40px;margin-bottom:50px">
                <div class="row">
                    <div class="col-md-4 col-sm-6">
                        <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/culture3.jpg" style="width:100%;height:200px;border:0"/>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="culture-div">
                            <p class="culture-p-style">企业愿景</p>
                            <p>让人人享有我们的贴心服务，让人人健康的活到120岁</p>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/culture2.jpg" style="width:100%;height:200px;border:0"/>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="culture-div">
                            <p class="culture-p-style">企业理念</p>
                            <p>脚踏实地，感恩惜福</p>
                        </div> 
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <img src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/honor/culture1.jpg" style="width:100%;height:200px;border:0"/>
                    </div>
                    <div class="col-md-4 col-sm-6">
                        <div class="culture-div">
                            <p class="culture-p-style">企业价值观</p>
                            <p>集众人之力，成细节之美 不断改进，永不满足 全力以赴，决不放弃</p>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <component :is="this.headerFooter.Footer"></component>
    </div>
</template>

<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import store from "@/store"
export default {
    components: {
        Header,
        Footer
    },
    data(){
        return{
            
        }
    },
    computed: {
		headerFooter() {
            return store.getters['auth/isLoggedIn'] ? {
                Footer: Footer,
            } : {
                header: Header,
                Footer: Footer
            }
        }
	},
    methods: {
        
    }
};
</script>
<style scoped>
a {
    text-decoration: none;
    color: black;
}

.router-link-active {
    text-decoration: none;
}
</style>