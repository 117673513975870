<template>
  <div>
    <component :is="this.headerFooter.header"></component>
    <vue-element-loading :active="isLoading" is-full-screen text="请稍后..." />
    <div class="container">
      <div class="ab-group">
        <div class="col-12 col-centered">
          <nav
            aria-label="breadcrumb"
            style="margin-top: 20px; background-color: white"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item" style="opacity: 0.7">
                <router-link to="/" style="opacity: 0.7">首页</router-link>
              </li>
              <li
                class="breadcrumb-item active"
                aria-current="page"
                style="opacity: 0.7"
              >
                联系我们
              </li>
            </ol>
          </nav>
          <h3 class="contact-or-style" style="margin-bottom: -30px">
            CONTACT US
          </h3>
          <h3 class="contact-or-style">--联系方式--</h3>

          <div
            class="card mb-3"
            style="max-width: 100%; height: 500px; margin-top: 40px"
          >
            <div class="row no-gutters">
              <div class="col-md-5">
                <div class="card-body" style="text-align: center">
                  <h2 class="card-title" style="margin-top: 10px">
                    河南华夏以斯帖生物科技有限公司
                  </h2>
                  <p class="card-text contact-title-style">
                    公司地址:郑州高新区长椿路11号研发5号楼1308室
                  </p>
                  <p class="card-text contact-title-style">
                    售前电话: 廖经理 18639003605
                  </p>
                  <p class="card-text contact-title-style">
                    售后电话: 贺经理 13140193605 15038388768
                  </p>
                  <p class="card-text contact-title-style">
                    客服电话: 0371-86258797
                  </p>
                  <p class="card-text contact-title-style">
                    邮箱：liao1037392291@126.com
                  </p>
                  <p class="card-text contact-title-style">
                    网址：https://www.hxesther.com/
                  </p>
                </div>
              </div>
              <div class="col-md-7" v-viewer>
                <img
                  src="https://est-hxesther-bucket.oss-cn-hangzhou.aliyuncs.com/hxesther/website-image/companyMap.png"
                  style="width: 100%; height: 500px"
                />
              </div>
            </div>
          </div>

          <h3
            class="contact-or-style"
            style="margin-bottom: -30px; margin-top: 100px"
          >
            ONLINE MESSAGE
          </h3>
          <h3 class="contact-or-style">--在线留言--</h3>

          <b-card style="margin-top: 50px; margin-bottom: 60px">
            <form>
              <div class="form-group">
                <label for="exampleFormControlInput1">联系人</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  v-model="contact_person"
                />
              </div>
              <div class="form-group" style="margin-top: 20px">
                <label for="exampleFormControlInput1">联系方式</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  v-model="phone"
                  placeholder="手机号或邮箱"
                />
              </div>
              <div class="form-group" style="margin-top: 20px">
                <label for="exampleFormControlTextarea1"
                  >留言内容(限300字内)</label
                >
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  v-model="message"
                ></textarea>
              </div>
            </form>
            <b-button
              variant="primary"
              style="margin-top: 20px"
              @click="createMeaage"
              >送出</b-button
            >
          </b-card>
        </div>
      </div>
    </div>
    <component :is="this.headerFooter.Footer"></component>
  </div>
</template>

<script>
import config from "@/config.js";
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import store from "@/store";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      contact_person: "",
      phone: "",
      message: "",
      isLoading: false,
    };
  },
  computed: {
    headerFooter() {
      return store.getters["auth/isLoggedIn"]
        ? {
            Footer: Footer,
          }
        : {
            header: Header,
            Footer: Footer,
          };
    },
  },
  methods: {
    createMeaage() {
      if (
        this.contact_person !== "" &&
        this.phone !== "" &&
        this.message !== ""
      ) {
        this.isLoading = true;
        const api = `${config.ApiURL}/contactus/createMessage`;
        const json = {
          contact_person: this.contact_person,
          phone: this.phone,
          message: this.message,
        };
        this.$http.post(api, json).then((res) => {
          if (res.data.Status === "success") {
            alert("成功送出留言，客服会尽快与你联络。");
            this.contact_person = "";
            this.phone = "";
            this.message = "";
            this.isLoading = false;
          }
        });
      } else {
        alert("栏位不可以是空白");
      }
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
  color: black;
}

.router-link-active {
  text-decoration: none;
}
</style>
